import axios from 'axios';

//export const ADD_PRODUCT_REVIEWES = "ADD_PRODUCT_REVIEWES";
export const FETCH_PRODUCT_REVIEWS = "FETCH_PRODUCT_REVIEWS";


// add Product Reviews
//export const AddProductReviewes = (review) => {
//    return dispatch => {
//        dispatch({ type: ADD_PRODUCT_REVIEWES });
//    };
//};

//fetch product reviwew
export const FetchProdReviewsSuccess = PR => {
    return dispatch => {
        dispatch({ type: FETCH_PRODUCT_REVIEWS, payload:  PR  });
    };
};


let axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    }
};
//export const AddProduct_Review = (reviewjson) => {
//    return dispatch => {
//        //console.log('Login Ation');
//        axios.post(process.env.REACT_APP_ENDPOINT + "api/Product/AddProduct_Reviews", reviewjson, axiosConfig)
//            .then(res => res.data)
//            .then(rev => dispatch(AddProductReviewes(rev)));
//    };
//};

export const  FetchProductReviews = (id) => {
    console.log(id);
    return dispatch => {
         axios.get(process.env.REACT_APP_ENDPOINT + "api/Product/GetProdReviewsByProdId?Id=" + id, axiosConfig)
             .then(res => res.data)
            .then(pr => dispatch(FetchProdReviewsSuccess(pr)));
    };
};



