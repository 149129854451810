import axios from 'axios';

export const LOGIN_SELLER_SUCCESS = "LOGIN_SELLER_SUCCESS";
export const LOGOUT_SELLER_SUCCESS = "LOGOUT_SELLER_SUCCESS";
export const UPDATE_SELLER_SUCCESS = "UPDATE_SELLER_SUCCESS";
export const DESTROY_SESSION = "destroy_session";

const LoginSellerSuccess = SellerUser => ({
    type: LOGIN_SELLER_SUCCESS,
    payload: SellerUser
});


const LogoutSellerSuccess = SellerUser => ({
    type: LOGOUT_SELLER_SUCCESS,
    payload: SellerUser
});

const UpdateSellerSuccess = SellerUser => ({
    type: UPDATE_SELLER_SUCCESS,
    payload: SellerUser
});


let axiosConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
    }
};
export const LoginSeller = (SellerCredentials, addToast) => {
    return dispatch => {
        //console.log('Login Ation');

        axios.post(process.env.REACT_APP_ENDPOINT + "api/Seller/Login", SellerCredentials, axiosConfig) //?UserName=" + SellerCredentials.username + '&Password=' + SellerCredentials.password
            .then(res => res.data)
            .then(user => {
                dispatch(LoginSellerSuccess(user));
                addToast("Login Successfull", { appearance: "success", autoDismiss: true });
            })
            .catch(error => {
                if (error.response.status === 401) {
                    addToast("Invalid Login Credentials", { appearance: "error", autoDismiss: true });
                }

            });
    };
};

export const LoginSellerUsingFB = (accessToken, addToast) => {
    return dispatch => {
        //console.log(accessToken);
        var form_data = new FormData();
        form_data.append("jsondata", JSON.stringify({ "accessToken": accessToken }));
        axios.post(process.env.REACT_APP_ENDPOINT + "api/Seller/LoginFB", form_data, axiosConfig)
            .then(res => res.data)
            .then(user => {
                dispatch(LoginSellerSuccess(user));
                addToast("Login Successfull", { appearance: "success", autoDismiss: true });
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        addToast("Invalid Login Credentials", { appearance: "error", autoDismiss: true });
                    }
                }
                else {
                    addToast("Something went wrong", { appearance: "error", autoDismiss: true });
                }

            });
    };
};

export const LogoutSeller = (SellerCredentials) => {
    return dispatch => {
        //  console.log('Logout Ation');
        // console.log(SellerCredentials);
        dispatch(LogoutSellerSuccess(SellerCredentials));
    };
};


export const UpdateSeller = (SellerCredentials) => {
    return dispatch => {
        //  console.log('Logout Ation');
        // console.log(SellerCredentials);
        dispatch(UpdateSellerSuccess(SellerCredentials));
    };
};

export const onClear = () => {
    return { type: DESTROY_SESSION };
};
