export const SET_ORDER_NUMBER = "SET_ORDER_NUMBER";
//add to cart
export const setOrderNumber = (
    orderId
) => {
    return dispatch => {
        //console.log(orderId+ " from action");
        dispatch({
            type: SET_ORDER_NUMBER,
            payload: {
                orderId
            }
        });
    };
};