import axios from 'axios';
import { fetchProductCat } from './ProductCatActions'

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});
 
// fetch products
//export const fetchProducts = products => {
//    return dispatch => {
//        if (products)
//    dispatch(fetchProductsSuccess(products));
//  };
//};
let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
};
export const fetchProducts =()  => {
    return dispatch => {
        axios.get(process.env.REACT_APP_ENDPOINT + "api/Seller/GetProducts", axiosConfig)
            .then(res => res.data)
            .then(products => {
                dispatch(fetchProductsSuccess(products));
                dispatch(fetchProductCat());
            });
    
    };
};
