
import axios from 'axios';
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const GET_WISHLIST = "GET_WISHLIST";
let axiosConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
    }
};
// add to wishlist
export const addToWishlist = (item, addToast, sellerId, selectedProductVarId, selectedProductSizeId) => {

    return async dispatch => {
        const wishlist = {
            sellerId: sellerId,
            productId: item.id,
            productVariationId: selectedProductVarId ? selectedProductVarId : item.variation[0].VariationId,
            productVariationSizeId: selectedProductSizeId ? selectedProductSizeId : item.variation[0].size[0].SizeId
        }
        //console.log(wishlist);
        let form_data = new FormData();
        form_data.append("wishListJson", JSON.stringify(wishlist));
        //console.log(JSON.stringify(wishlist));
        await axios.post(process.env.REACT_APP_ENDPOINT + "api/WishList/AddWishListItems", form_data, axiosConfig)
            .then(res => {
                if (res.data > 0) {
                    if (addToast) {
                        addToast("Added To Wishlist", { appearance: "success", autoDismiss: true });

                    }
                    return dispatch(getWishList(sellerId));
                }
                else if (res.data === -1) {
                    if (addToast) {
                        addToast("Already in Wishlist", { appearance: "success", autoDismiss: true });

                    }
                    return dispatch(getWishList(sellerId));
                }

            })
            .catch(error => {
                if (error.response.status === 401) {
                    addToast("something went wrong. please try again", { appearance: "error", autoDismiss: true });
                    //console.log("something went wrong from if" + error.response.status);
                }
                else {
                    console.log("something went wrong" + error.response.status);
                }

            });
        dispatch({ type: ADD_TO_WISHLIST, payload: item });
    }

};


// delete from wishlist
export const deleteFromWishlist = (item, addToast) => {
    return dispatch => {
        if (addToast) {
            addToast("Removed From Wishlist", {
                appearance: "error",
                autoDismiss: true
            });
        }
        dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
    };
};

//delete all from wishlist
export const deleteAllFromWishlist = addToast => {
    return dispatch => {
        if (addToast) {
            addToast("Removed All From Wishlist", {
                appearance: "error",
                autoDismiss: true
            });
        }
        dispatch({ type: DELETE_ALL_FROM_WISHLIST });
    };
};
////////////////////////////////
let axiosConfig_get = {
    headers: {
        //'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    }
};
////////////////////////////////
//get WishList
export const getWishList = (sellerId) => {
    return async dispatch => {
        //Insert api call fro get WishList
        await axios.get(process.env.REACT_APP_ENDPOINT + "api/cart/GetWishListBySeller?sellerId=" + sellerId, axiosConfig_get)
            .then((response) => {

                dispatch({
                    type: GET_WISHLIST,
                    payload: response.data,

                });
            })
            .catch((err) => { console.log(err) });
        //////////////////////////////////////////////////////

    };
}
