import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple"; //{clear }
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
//import products from "./data/Homeproducts.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
//import axios from 'axios';
//import { setProductSession } from './Utils/Common';
import { composeWithDevTools } from "redux-devtools-extension";
//import { initFacebookSdk } from './Utils/Common';

const devTools =
    process.env.NODE_ENV === "production"
        ? applyMiddleware(thunk, save())
        : composeWithDevTools(applyMiddleware(thunk, save()));
//Clear the local storage
//clear();
const store = createStore(
  rootReducer,
  load(),
  devTools
);
//composeWithDevTools(applyMiddleware(thunk, save()))

//let axiosConfig = {
//    headers: {
//        'Content-Type': 'application/json;charset=UTF-8',
//        "Access-Control-Allow-Origin": "*",
//    }
//};
//const config = {
//    headers: { Authorization: `Bearer ${getToken()}` }
//};

//const bodyParameters = {
//    key: "value"
//};

//const [data, setData] = React.useState('');

//async function fetchData() {
//    const response = await axios.get(process.env.REACT_APP_ENDPOINT + "api/Seller/GetProducts", axiosConfig);
//    setProductSession(response.data);
//    // setProductSession(products);
//    //setData(response.data);
//};

//console.log(axios.get('api/seller/GetProducts')
//  .then(response => JSON.parse(response.data)));
//fetchData();
//console.log(getProductsSession());




//useEffect(() => {

//    //const config = {
//    //    headers: { Authorization: `Bearer ${getToken()}` }
//    //};
//    //const bodyParameters = {
//    //    key: "value"
//    //};
//    //let results = axios.get('api/seller/GetProducts', bodyParameters, config);
//    //try {
//    //    let response = JSON.parse(results.request.response)
//    //   // dispatch({ type: Actions.RECEIVE_DATA, payload: response });
//    //    console.log(response)
//    //} catch (err) {
//    //   // dispatch({ type: Actions.FETCH_DATA_ERROR, payload: err });
//    //}
//    //    .then(response => {

//    //        dispatch({ type: Actions.RECEIVE_DATA, payload: response })

//    //}).catch(error => {
//    //    setLoading(false);
//    //    if (error.response.status === 401) setError(error.response.data.message);
//    //    else setError("Something went wrong. Please try again later.");
//    //});
    
//});


//componentDidMount() {
    
//    const config = {
//        headers: { Authorization: `Bearer ${getToken()}` }
//    };
//    const bodyParameters = {
//        key: "value"
//    };
//    let resaults = axios.get('api/seller/GetProducts', bodyParameters, config);
//    try {
//        let response = JSON.parse(results.request.response)
//        dispatch({ type: Actions.RECEIVE_DATA, payload: response });
//    } catch (err) {
//        dispatch({ type: Actions.FETCH_DATA_ERROR, payload: err });
//    }
//    //    .then(response => {
        
//    //        dispatch({ type: Actions.RECEIVE_DATA, payload: response })
        
//    //}).catch(error => {
//    //    setLoading(false);
//    //    if (error.response.status === 401) setError(error.response.data.message);
//    //    else setError("Something went wrong. Please try again later.");
//    //});
//}
// fetch products from json file
//store.dispatch(fetchProducts(products));
//fetch products from api
store.dispatch(fetchProducts());         //getProductsSession()

require('dotenv').config()

//initFacebookSdk().then(startApp);
//function startApp() {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root")
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();

//}
