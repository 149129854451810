import {
    ADD_BILLING_INFO,
    DELETE_BILLING_INFO
} from "../actions/BillingInfoActions";

const initState = {
    BillingInfo: {}
};

const BillingInfoReducer = (state = initState, action) => {

    if (action.type === ADD_BILLING_INFO) {
        return {
            ...state,
            BillingInfo : action.payload,
        }
    }
    if (action.type === DELETE_BILLING_INFO) {
        //return BillingInfo .filter(item => {
        //    return false;
        //});
        return {
            ...state,
            BillingInfo: null,
        }
    }

return state;
};

export default BillingInfoReducer;
