import { LOGIN_SELLER_SUCCESS, LOGOUT_SELLER_SUCCESS, UPDATE_SELLER_SUCCESS, DESTROY_SESSION  } from "../actions/LoginActions";


const initState = {
    user: ''
    
};

const LoginReducer = (state = initState, action) => {
    if (action.type === LOGIN_SELLER_SUCCESS) {
        return {
            ...state,
            user: action.payload
            
        };
    }
    if (action.type === LOGOUT_SELLER_SUCCESS) {
        return {
            ...state,
            user: action.payload

        };
    }

    if (action.type === UPDATE_SELLER_SUCCESS) {
        return {
            ...state,
            user: action.payload 

        };
    }

    if (action.type === DESTROY_SESSION)
        state = null;


    return state;
};
   
export default LoginReducer;
