import axios from 'axios';
export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const ADD_SELLER_MARGIN = "ADD_SELLER_MARGIN";
export const GET_CART = "GET_CART";
export const FLUSH_CART_STATE = "FLUSH_CART_STATE";


let axiosConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
    }
};
//add to cart
export const addToCart = (
    item,
    addToast,
    quantityCount,
    selectedProductColor,
    selectedProductSize,
    sellerMargin,
    sellerId,
    selectedProductVarId,
    selectedProductSizeId
) => {
    return async dispatch => {
        //////////////////////////////////////////
        const cart = {
            cartItemId: 0,
            sellerId: sellerId,
            productId: item.id,
            productVariationId: selectedProductVarId,//item.variation[0].VariationId,
            productVariationSizeId: selectedProductSizeId,//item.variation[0].size[0].SizeId,
            cartQty: quantityCount,
            sellerMargin: sellerMargin

        }
        //console.log(cart);
        let form_data = new FormData();
        form_data.append("cartJson", JSON.stringify(cart));
        await axios.post(process.env.REACT_APP_ENDPOINT + "api/cart/AddCartItems", form_data, axiosConfig)
            .then(res => {

                if (res.data > 0) {
                    //dispatch(LoginSellerSuccess(user));
                    //addToast("Login Successfull", { appearance: "success", autoDismiss: true });
                    //console.log("cart item added with id" + res.data );
                    if (addToast) {
                        addToast("Added To Cart", { appearance: "success", autoDismiss: true });

                    }
                    return dispatch(getCart(sellerId));
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                     addToast("something went wrong. please try again", { appearance: "error", autoDismiss: true });
                    //console.log("something went wrong from if" + error.response.status);
                }
                else {
                    console.log("something went wrong" + error.response.status);
                }

            });
        //////////////////////////////////////////

        dispatch({
            type: ADD_TO_CART,
            payload: {
                ...item,
                quantity: quantityCount,
                selectedProductColor: selectedProductColor
                    ? selectedProductColor
                    : item.selectedProductColor
                        ? item.selectedProductColor
                        : null,
                selectedProductSize: selectedProductSize
                    ? selectedProductSize
                    : item.selectedProductSize
                        ? item.selectedProductSize
                        : null,
                sellerMargin
            }
        });


    };
};
//decrease from cart
export const decreaseQuantity = (item, addToast, sellerMargin, sellerId) => {
    return async dispatch => {
        //////////////////////////////////////////
        const cart = {
            cartItemId: 0,
            sellerId: sellerId,
            productId: item.id,
            productVariationId: item.variation[0].VariationId,
            productVariationSizeId: item.variation[0].size[0].SizeId,
            cartQty: -1,
            sellerMargin: sellerMargin

        }
        //   console.log(cart);
        let form_data = new FormData();
        form_data.append("cartJson", JSON.stringify(cart));
        await axios.post(process.env.REACT_APP_ENDPOINT + "api/cart/AddCartItems", form_data, axiosConfig)
            .then(res => {

                if (res.data > 0) {
                    //dispatch(LoginSellerSuccess(user));
                    //addToast("Login Successfull", { appearance: "success", autoDismiss: true });
                    //console.log("cart item added with id" + res.data);
                    if (addToast) {
                        addToast("Item Decremented From Cart", {
                            appearance: "warning",
                            autoDismiss: true
                        });
                    }
                    return dispatch(getCart(sellerId));
                }
                else if (res.data < 0) {
                    if (addToast) {
                        addToast("Item Removed From Cart Due to  Decrement", {
                            appearance: "warning",
                            autoDismiss: true
                        });
                    }
                    return dispatch(getCart(sellerId));
                }

            })
            .catch(error => {
                if (error.response.status === 401) {
                    //               addToast("something went wrong", { appearance: "error", autoDismiss: true });
                    console.log("something went wrong from if" + error.response.status);
                }
                else {
                    console.log("something went wrong" + error.response.status);
                }

            });
        //////////////////////////////////////////

        dispatch({ type: DECREASE_QUANTITY, payload: item });
    };
};
//delete from cart
export const deleteFromCart = (item, addToast, sellerId) => {
    return async dispatch => {
        await axios.post(process.env.REACT_APP_ENDPOINT + "api/cart/DeleteCartItemBySeller/" + sellerId + "/" + item.cartItemId, axiosConfig_get)
            // await axios.delete("https://jsonplaceholder.typicode.com/posts/1")
            .then((response) => {
                if (response.data === 1) {
                    if (addToast) {
                        addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
                    }
                }
                return dispatch(getCart(sellerId));
            })
            .catch((err) => { console.log(err) });
        dispatch({ type: DELETE_FROM_CART, payload: item });
    };
};
//delete all from cart
export const deleteAllFromCart = (addToast, sellerId) => {

    return async dispatch => {
        await axios.post(process.env.REACT_APP_ENDPOINT + "api/cart/DeleteCartItemBySeller/" + sellerId)
            .then((response) => {
                if (response.data === 1) {
                    if (addToast) {
                        addToast("Removed All From Cart", { appearance: "error", autoDismiss: true });
                    }
                }
                return dispatch(getCart(sellerId));
            }).catch((err) => { console.log(err) });

        dispatch({ type: DELETE_ALL_FROM_CART });
    };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
    if (item.stock) {
        return item.stock;
    } else {
        return item.variation
            .filter(single => single.color === color)[0]
            .size.filter(single => single.name === size)[0].stock;
    }
};

//add seller margin and final unit price (unit price+discount)
export const addSellerMargin = (
    item,
    //addToast,
    SellerMargin,
    FinalUnitPrice,
    sellerId

) => {
    return dispatch => {
        //if (addToast) {
        //    addToast("Seller Margin Added", { appearance: "success", autoDismiss: true });
        //}
        dispatch({
            type: ADD_SELLER_MARGIN,
            payload: {
                ...item,
                sellerMargin: SellerMargin,
                finalUnitPrice: FinalUnitPrice
            }
        });
    };
};
////////////////////////////////
let axiosConfig_get = {
    headers: {
        //'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    }
};
////////////////////////////////
export const getCart = (sellerId) => {
    return async dispatch => {
        //Insert api call fro get cart
        await axios.get(process.env.REACT_APP_ENDPOINT + "api/cart/GetCartBySeller?sellerId=" + sellerId, axiosConfig_get)
            .then((response) => {
                //setData(response.data);
                //console.log("from get cart reducer");
                dispatch({
                    type: GET_CART,
                    payload: response.data,
                    //...item,
                    //quantity: quantityCount,
                    //selectedProductColor: selectedProductColor
                    //    ? selectedProductColor
                    //    : item.selectedProductColor
                    //        ? item.selectedProductColor
                    //        : null,
                    //selectedProductSize: selectedProductSize
                    //    ? selectedProductSize
                    //    : item.selectedProductSize
                    //        ? item.selectedProductSize
                    //        : null,
                    //sellerMargin

                });
            })
            .catch((err) => { console.log(err) });
        //////////////////////////////////////////////////////

    };
}

export const flushCartState = () => {

    return dispatch => {
        dispatch({
            type: FLUSH_CART_STATE
        });
    };
}


