import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import loginReducer from "./LoginReducer";
import BillinginfoReducer from "./BillingInfoReducer"
import ProductReviewsReducers from './ProductReviewsReducers'
//import AccountReducer from './AccountReducer'
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import orderReducer from "./OrderReducer";
import productCatReducer from './ProductCatReducer'


const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
    currencyData: currencyReducer,
    productData: productReducer,
    cartData: cartReducer,
    wishlistData: wishlistReducer,
    compareData: compareReducer,
    SellerUser: loginReducer,
    BillingInfo: BillinginfoReducer,
    ProductReviews: ProductReviewsReducers,
    //SellerUser: AccountReducer
    Order: orderReducer,
    productCat: productCatReducer,
});


export default rootReducer;
