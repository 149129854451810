import { FETCH_PRODUCT_REVIEWS } from "../actions/ProductReviewsActions";

const initState = {};

const ProductReviewsReducer = (state = initState, action) => {

    if (action.type === FETCH_PRODUCT_REVIEWS) {
        return {
            ...state,
            ProductReviews: action.payload
        };
    }


    return state;
};

export default ProductReviewsReducer;
