import { FETCH_PRODUCTCAT_SUCCESS } from "../actions/ProductCatActions";

const initState = {
    productCat: []
};

const productCatReducer = (state = initState, action) => {
    if (action.type === FETCH_PRODUCTCAT_SUCCESS) {
        return {
            ...state,
            productCat: action.payload,
        };
    }

    return state;
};

export default productCatReducer;
