import { SET_ORDER_NUMBER } from "../actions/OrderActions";

const initState = {
    orderId: 0
};

const orderReducer = (state = initState, action) => {
    //console.log(action.payload + " from reducer");
    if (action.type === SET_ORDER_NUMBER) {
        return  action.payload;

        
    }


    return state;
};

export default orderReducer;