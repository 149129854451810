export const ADD_BILLING_INFO = "ADD_BILLING_INFO";
export const DELETE_BILLING_INFO = "DELETE_BILLING_INFO";


// add Billing Info
export const addBillingInfo = (bInfo) => {
    return dispatch => {
        dispatch({ type: ADD_BILLING_INFO, payload: bInfo });
    };
};

//delete all from cart
export const deleteBillingInfo =() => {
    return dispatch => {
        dispatch({ type: DELETE_BILLING_INFO });
    };
};

